import router from 'next/router';
import React, { useRef, useState, MouseEvent, ChangeEvent, KeyboardEvent, Dispatch, SetStateAction } from 'react';
import { SearchIcon, Field, SearchWrapper } from './style';

export interface ISearchProps {
  showField: boolean;
  search: string;
  closeMenu?: Dispatch<SetStateAction<boolean>>;
}

const SearchField = ({ showField = false, search = '', closeMenu }: ISearchProps) => {
  const fieldRef = useRef(null);
  const [showSearchField, setShowSearchField] = useState<boolean>(showField);
  const [searchString, setSearchString] = useState<string>(search);

  const HandleSearchIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (searchString === '') {
      setShowSearchField(!showSearchField);
    } else {
      SearchSubmitted(searchString);
    }
  };

  const SearchSubmitted = (searchText: string) => {
    //cleanup

    if (!showField) {
      setSearchString('');
      setShowSearchField(false);
      closeMenu !== undefined ? closeMenu(true) : '';
    }

    //send to search results
    router.push(`/search/${encodeURIComponent(searchText)}`);
  };

  const HandleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      const input = event.target as HTMLInputElement;
      SearchSubmitted(input.value);
    }
  };

  const HandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(`${event.currentTarget.value}`);
  };

  return (
    <SearchWrapper>
      <Field
        ref={fieldRef}
        type="text"
        value={searchString}
        onChange={HandleChange}
        showField={showSearchField}
        placeholder="Search ..."
        onKeyDown={HandleKeyDown}
      />
      <SearchIcon>
        <button aria-label="Search" onClick={HandleSearchIconClick} />
      </SearchIcon>
    </SearchWrapper>
  );
};

export default SearchField;
