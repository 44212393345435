import tw, { styled } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';

export const SearchWrapper = styled.div(() => {
  const out = [
    tw`absolute`,
    {
      width: 300,
      top: 50,
      right: '10%',
      zIndex: 2,
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
        top: 'auto',
        bottom: -38,
      },
      [`@media screen and (max-width: ${BREAKPOINTS.xs}px)`]: {
        right: '14%',
      },
    },
  ];

  return out;
});

type SearchFieldProps = {
  showField: boolean;
};

export const Field = styled.input<SearchFieldProps>(({ showField }) => {
  const out = [
    tw`block text-center z-10 border-bordeaux float-right relative  text-left`,
    {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      height: 29,
      width: showField ? 'calc(100% - 29px)' : '1px',
      zIndex: 3,
      transition: 'width 1s',
      padding: showField ? '0 5px' : 0,
      marginRight: showField ? 0 : 2,
    },
  ];
  return out;
});

export const SearchIcon = styled.div(() => {
  const out = [
    [tw`block text-center absolute`],
    {
      right: 0,
      zIndex: 10,
      button: {
        '&:after': [
          tw`block float-left`,
          {
            content: "''",
            backgroundImage: 'url(/images/search.gif)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: 29,
            height: 29,
          },
        ],

        '&:before': [
          tw`border-solid bg-bordeaux inline-block`,
          {
            content: "''",
            width: 1,
            left: -1,
            height: 29,
            marginLeft: -59,
          },
        ],
      },
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
        //right: -29,
      },
    },
  ];
  return out;
});
